<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import Container from "@/components/Container.vue";
import appConfig from "../../../../app.config";

import axios from 'axios';
import AccountSidebar from "./AccountSidebar.vue";
import AccountMenuContent from "./AccountMenuContent.vue";
import AccountMenuSecurity from "./AccountMenuSecurity.vue";
import AccountMenuSettings from "./AccountMenuSettings.vue";

export default {
  page: {
    title: "Account",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Account",
      menu: 'account',
      data: {
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    Container,
    AccountSidebar,
    AccountMenuContent,
    AccountMenuSecurity,
    AccountMenuSettings,
  },
  created: function () {
    this.loadingProfile();
  },
  methods: {
    loadingProfile() {
      axios.get('/api/account/edit', { withCredentials: true })
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    },
    sidebarMenuChanged(e) {
      this.menu = e;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" v-if="false" />
    
    <Container>
      <template #sidebar>
        <AccountSidebar v-bind:data="data" @changed-sidebar-menu="sidebarMenuChanged"></AccountSidebar>
      </template>
      <template #content>
        <AccountMenuContent v-if="menu==='account'" v-bind:data="data" @refresh-profile="loadingProfile"></AccountMenuContent>
        <AccountMenuSecurity v-if="menu==='security'" v-bind:data="data" @refresh-profile="loadingProfile"></AccountMenuSecurity>
        <AccountMenuSettings v-if="menu==='settings'" v-bind:data="data" @refresh-profile="loadingProfile"></AccountMenuSettings>
      </template>
    </Container>
  </Layout>
</template>
