<script>
import axios from 'axios';

export default {
  data() {
    return {
      showAlert: false,
      buttonUpdateDisabled: true,
      previewImageAvatar: null,
      previewImageCover: null,
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  watch: { 
    data: function() {
      this.fillFieldsFromProps();
    }
  },
  mounted: function() {
    this.fillFieldsFromProps();
  },
  components: {
  },
  methods: {
    getField(key) {
      if(this.data && this.data.profile) {
        return this.data.profile[key];
      }
      return '';
    },
    uploadImageAvatar(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e =>{
          this.previewImageAvatar = e.target.result;
          this.buttonUpdateDisabled = false;
      };
    },
    clearImageAvatar() {
      this.previewImageAvatar = null;
      this.buttonUpdateDisabled = false;
      event.preventDefault();
    },
    uploadImageCover(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e =>{
          this.previewImageCover = e.target.result;
          this.buttonUpdateDisabled = false;
      };
    },
    clearImageCover() {
      this.previewImageCover = null;
      this.buttonUpdateDisabled = false;
      event.preventDefault();
    },
    fillFieldsFromProps: function() {
      this.previewImageAvatar = this.getField('urlAvatar');
      this.previewImageCover = this.getField('urlCover');
    },
    sendDetailsForm: function() {
      var r = this.validateForm();
      if(r) {
        //Api
        let data = {
          avatar: this.previewImageAvatar,
          cover: this.previewImageCover,
        };

        const vm = this;
        const vmParent = this.$parent;
        axios.post('/api/account/images', data, {
          withCredentials: true }).then(function () {
          vm.showAlert = true;
          vmParent.$emit('refreshProfile');
        }).catch(function () {
        });
      }
    },
    validateForm: function () {
      return true;
    },
  },
};
</script>

<template>
  <b-alert :show="showAlert" variant="success" dismissible>
      <strong>Success!</strong> Form was saved
  </b-alert>

  <form v-on:submit.prevent="sendDetailsForm">
    <div class="row">
      <div class="col-lg-4">
        <input type="file" ref="fileAvatar" accept="image/jpeg" class="d-none" @change=uploadImageAvatar>
        <div class="border-dashed rounded p-0 text-center border-1" role="button" @click="$refs.fileAvatar.click()">
          <button class="btn btn-danger btn-icon waves-effect waves-light position-absolute" @click.stop="clearImageAvatar" style="top:15px;right:25px;">
            <i class="ri-delete-bin-5-line"></i>
          </button>
          <img :src="previewImageAvatar" class="w-100 m-0 p-0" />
          <div class="mt-4 mb-5">
            <h5><i class="ri-upload-line"></i> avatar</h5>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <input type="file" ref="fileCover" accept="image/jpeg" class="d-none" @change=uploadImageCover>
        <div class="border-dashed rounded p-0 text-center border-1" role="button" @click="$refs.fileCover.click()">
          <img :src="previewImageCover" class="w-100 m-0 p-0" />
          <button class="btn btn-danger btn-icon waves-effect waves-light position-absolute" @click.stop="clearImageCover" style="top:15px;right:25px;">
            <i class="ri-delete-bin-5-line"></i>
          </button>
          <div class="mt-4 mb-5">
            <h5><i class="ri-upload-line"></i> cover</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="hstack gap-2 justify-content-end mt-3">
        <button type="submit" class="btn btn-primary" v-bind:disabled="buttonUpdateDisabled"> Update </button>
      </div>
    </div>
  </form>
</template>