<script>
import axios from 'axios';

export default {
  data() {
    return {
      isInvalidDisplayName: false,
      showAlert: false,

      displayName: '',
      login: '',
      firstName: '',
      lastName: '',
      location: '',
      url: '',
      memo: '',
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  watch: { 
    data: function() {
      this.fillFieldsFromProps();
    }
  },
  mounted: function() {
    this.fillFieldsFromProps();
  },
  components: {
  },
  methods: {
    getField(key) {
      if(this.data && this.data.profile) {
        return this.data.profile[key];
      }
      return '';
    },
    fillFieldsFromProps: function() {
      this.displayName = this.getField('displayName');
      this.login = this.getField('login');
      this.firstName = this.getField('firstName');
      this.lastName = this.getField('lastName');
      this.location = this.getField('location');
      this.url = this.getField('url');
      this.memo = this.getField('memo');
    },
    sendDetailsForm: function() {
      var r = this.validateForm();
      if(r) {
          var data = {
              displayName: this.displayName,
              firstName: this.firstName,
              lastName: this.lastName,
              location: this.location,
              url: this.url,
              memo: this.memo,
          };

          const vm = this;
          const vmParent = this.$parent;
          axios.post('/api/account/update', data, { withCredentials: true }).then(function () {
              vm.showAlert = true;
              vmParent.$emit('refreshProfile');
          });
      }
    },
    validateForm: function () {
        this.isInvalidDisplayName = !this.displayName;

        if(!(this.isInvalidDisplayName))
            return true;
        
        return false;
    },
  },
};
</script>

<template>
  <b-alert :show="showAlert" variant="success" dismissible>
      <strong>Success!</strong> Form was saved
  </b-alert>

  <form v-on:submit.prevent="sendDetailsForm" class="needs-validation">
    <div class="row">

      <div class="col-lg-6">
        <div class="mb-3">
          <label for="displayNameInput" class="form-label">Display Name</label>
          <input type="text" class="form-control" id="displayNameInput" placeholder="Enter your display name" v-model="displayName" v-bind:class="{ 'is-invalid': isInvalidDisplayName }">
          <div class="invalid-feedback">Please enter your display name.</div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="displayLoginInput" class="form-label">Login</label>
          <input type="text" class="form-control" id="displayLoginInput" placeholder="Login" v-model="login" readonly="readonly">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="firstnameInput" class="form-label">First Name</label>
          <input type="text" class="form-control" id="firstnameInput" placeholder="Enter your firstname" v-model="firstName">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="lastnameInput" class="form-label">Last Name</label>
          <input type="text" class="form-control" id="lastnameInput" placeholder="Enter your lastname" v-model="lastName">
        </div>
      </div>

      <div class="col-lg-6">
        <div class="mb-3">
          <label for="designationInput" class="form-label">Location</label>
          <input type="text" class="form-control" id="designationInput" placeholder="Country, city etc." v-model="location">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="mb-3">
          <label for="websiteInput1" class="form-label">Website</label>
          <input type="text" class="form-control text-primary" id="websiteInput1" placeholder="https://www.example.com" v-model="url">
        </div>
      </div>
      
      <div class="col-lg-12">
        <div class="mb-3 pb-2">
          <label for="exampleFormControlTextarea" class="form-label">Description</label>
          <textarea class="form-control" id="exampleFormControlTextarea" placeholder="Enter your description" rows="10" v-model="memo"></textarea>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="hstack gap-2 justify-content-end">
          <button type="submit" class="btn btn-primary"> Update </button>
        </div>
      </div>
    </div>
  </form>
</template>