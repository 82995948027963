<script>
import axios from 'axios';

export default {
  data() {
    return {
    }
  },
  props: {
    name: String,
    title: String,
    memo: String,
    value: Boolean,
  },
  components: {
  },
  methods: {
    boolChanged: function(e) {
      var data = {
          name: this.name,
          value: e.target.checked,
      };

      axios.post('/api/account/settings/bool', data, { withCredentials: true })
        .then(function () {
        })
        .catch(function () {
        });
    },
  },
  computed: {
    getCheckedValue: function() {
      return this.value;
    },
  },
};
</script>

<template>
  <li class="d-flex">
    <div class="flex-grow-1">
      <label :for="name" class="form-check-label fs-14">{{ title }}</label>
      <p class="text-muted">{{ memo }}</p>
    </div>
    <div class="flex-shrink-0">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" :id="name" v-model="getCheckedValue" @change="boolChanged">
      </div>
    </div>
  </li>
</template>