<script>
import AccountDetails from "./_Details.vue";
import AccountImages from "./_Images.vue";
import AccountLinks from "./_Links.vue";

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    AccountDetails,
    AccountImages,
    AccountLinks,
  },
  methods: {
  }
};
</script>

<template>
  <div class="card mt-xxl">
    <div class="card-header">
      <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
        <li class="nav-item">
          <a class="nav-link text-body active" data-bs-toggle="tab" href="#personalDetails" role="tab" aria-selected="true">
            Personal Details
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-body" data-bs-toggle="tab" href="#images" role="tab" aria-selected="false">
            Avatar &amp; Cover
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-body" data-bs-toggle="tab" href="#personalLinks" role="tab" aria-selected="false">
            Links 
          </a>
        </li>
      </ul>
    </div>
    <div class="card-body p-4">
      <div class="tab-content">
        <div class="tab-pane active" id="personalDetails" role="tabpanel">
          <AccountDetails v-bind:data="data"></AccountDetails>
        </div>
        <div class="tab-pane" id="images" role="tabpanel">
          <AccountImages v-bind:data="data"></AccountImages>
        </div>
        <div class="tab-pane" id="personalLinks" role="tabpanel">
          <AccountLinks v-bind:data="data"></AccountLinks>
        </div>
      </div>
    </div>
  </div>
</template>