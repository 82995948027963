<script>
import axios from 'axios';

export default {
  data() {
    return {
      isInvalidOldPassword: false,
      isInvalidNewPassword: false,
      passwordExist: false,
      showAlert: false,
      oldpassword: '',
      newpasswordInput: '',
      confirmpasswordInput: '',
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  mounted() {
  },
  methods: {
    sendSecurityForm: function() {
      var r = this.validateForm();
      if(r) {
          var data = {
              oldPassword: this.oldpassword,
              newPassword1: this.newpasswordInput,
              newPassword2: this.confirmpasswordInput
          };

          const vm = this;
          //const vmParent = this.$parent;
          axios.post('/api/account/security', data, { withCredentials: true }).then(function () {
            vm.clearFields();
            vm.showAlert = true;
            vm.passwordExist = true;
            vm.isInvalidOldPassword = false;
            vm.isInvalidNewPassword = false;
            //vmParent.$emit('refreshProfile');
          }).catch(function() {
            vm.isInvalidOldPassword = true;
            vm.clearFields();
          });
      }
    },
    validateForm: function () {
        if(this.data.profile.hasPassword)
          this.isInvalidOldPassword = this.oldpassword === '';
        else
          this.isInvalidOldPassword = false;

        if(this.newpasswordInput)
          this.isInvalidNewPassword = this.newpasswordInput !== this.confirmpasswordInput;
        else
            this.isInvalidNewPassword = true;

        if(!(this.isInvalidNewPassword))
            return true;
        
        return false;
    },
    clearFields: function() {
      this.oldpassword = '';
      this.newpasswordInput = '';
      this.confirmpasswordInput = '';
    }
  },
  computed: {
    isPasswordExist: function() {
      return this.data.profile.hasPassword || this.passwordExist;
    },
  },
};
</script>

<template>
  <b-alert :show="showAlert" variant="success" dismissible>
      <strong>Success!</strong> Form was saved
  </b-alert>

  <form v-on:submit.prevent="sendSecurityForm" class="needs-validation">
    <div class="row g-2">
      <div class="col-lg-4">
        <div>
          <label for="oldpasswordInput" class="form-label">Old Password</label>
          <input type="password" class="form-control" id="oldpasswordInput" v-model="oldpassword" placeholder="Enter current password" v-bind:disabled="!isPasswordExist" v-bind:class="{ 'is-invalid': isInvalidOldPassword }">
        </div>
      </div>
      <div class="col-lg-4">
        <div>
          <label for="newpasswordInput" class="form-label">New Password</label>
          <input type="password" class="form-control" id="newpasswordInput" v-model="newpasswordInput" placeholder="Enter your new password" v-bind:class="{ 'is-invalid': isInvalidNewPassword }">
        </div>
      </div>
      <div class="col-lg-4">
        <div>
          <label for="confirmpasswordInput" class="form-label">Confirm Password</label>
          <input type="password" class="form-control" id="confirmpasswordInput" v-model="confirmpasswordInput" placeholder="Confirm new password" v-bind:class="{ 'is-invalid': isInvalidNewPassword }">
        </div>
      </div>
      <div class="col-lg-12">
        <div class="text-end mt-3">
          <button type="button" @click="sendSecurityForm" class="btn btn-primary"> Change Password </button>
        </div>
      </div>
    </div>
  </form>
</template>